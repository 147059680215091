import React from "react"

export default () => (
  <div className="o-table__container my-40">
    <h2 className="h2">Directors&#8217; shareholdings</h2>

    <p className="o-table__title">(as at 24 July 2024)</p>

    <table className="o-table o-table--llr">
      <thead>
        <tr>
          <th>Director</th>
          <th>Shareholding</th>
          <th>Percentage of issued share capital</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>Tom Libassi (has an interest in)</td>
          <td>13,413,500*</td>
          <td>18.52%</td>
        </tr>
        <tr>
          <td>N J Hanbury (either personally or has an interest in)</td>
          <td>9,494,725**</td>
          <td>13.00%</td>
        </tr>
        <tr>
          <td>Arthur Manners (either personally or has an interest in)</td>
          <td>1,197,509***</td>
          <td>1.65%</td>
        </tr>
        <tr>
          <td>John Chambers (either personally or has an interest in)</td>
          <td>560,000</td>
          <td>0.77%</td>
        </tr>
        <tr>
          <td>Michael Wade</td>
          <td>116,470</td>
          <td>0.16%</td>
        </tr>
        <tr>
          <td>Andrew Christie</td>
          <td>34,551</td>
          <td>0.05%</td>
        </tr>
      </tbody>
    </table>
  </div>
)
